import "../css/GetCoaching.scss";

function GetCoaching() {
  const getCoaching =
    "With over 28 years of experience in the online health and fitness industry, we pride ourselves on being the best coaching company in the world. We’ve helped over 100,000 individuals achieve their goal weight through expertly crafted plans tailored to their unique hormonal profile. We are committed to equipping each individual with the tools necessary for sustained, long-term weight management that aligns with their individual lifestyle and preferences.";

  return (
    <div className="get-coaching-container App-container">
      <div className="get-coaching-section App-section">
        <div className="section-illustration"></div>

        <div className="boxed-description">
          <div className="description-subtext text-align-right">
            <span className="uppercase-bolded-font gray-font">We are </span>
            <span className="text-shadow large-font blue-font">committed</span>
            <span className="uppercase-bolded-font gray-font"> to your </span>
            <span className="text-shadow large-font blue-font"> success</span>
          </div>
          <div className="description-text gray-font">{getCoaching}</div>
          <div className="read-more-button">
            <div className="read-more-text">
              <a
                href="https://forms.gle/1YYFE6kEhrmuvdhe8"
                rel="noreferrer"
                target="_blank"
              >
                Sign Up
              </a>
            </div>
            <div className="read-more-line"></div>
            <div className="read-more-arrow"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetCoaching;
